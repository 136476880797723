import React from 'react';
import './card.scss';

export const CardsBlog = ({titulo, data, descricao, Rota}) => {

    return (

        <div className='blog-post-card-info-wrapper'>
            <i>{data}</i>
            <h3>{titulo}</h3>
            <p>{descricao}</p>
            <a href={Rota} target="blank" alt="THL - soluções em tecnologia">
                Leia mais __
            </a>
        </div>
    )
}
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { GoQuote } from 'react-icons/go';
import './cases.scss';

const Cases = () => {

    const InfoCases = {
        titulo: 'Cases de Sucesso',
        describe: 'No início da pandemia de Corona Vírus, fomos pegos de surpresa pelo lockdown. Contamos com a excelente atuação da equipe da THL Soluções, que nos auxiliou na migração da nossa agência para o sistema Home Office. Ótimos produtos e ótimos profissionais. Recomendo.”',
    }
    return (
        <section className='cases-wrapper'>
            <div className='cases-image-wrapper'>
                <StaticImage src='../../images/CasesDeSucesso.png' loading='eager' placeholder='blurred'/> 
            </div>
            <div className='cases-info-wrapper'>
                <div className='cases-titulo-info'>
                    <h3>{`${InfoCases.titulo}`}</h3>
                </div>
                <div className='cases-depoimentos-wrap'>
                    <i className='cases-quote'>
                        <GoQuote/>
                    </i>
                    <p>{`${InfoCases.describe}`}</p>
                    <h5>
                        Luis Henrique
                    </h5>
                    <h6>
                        Agência Dheegital
                    </h6>
                </div>
            </div>
        </section>
    )
}

export default Cases;
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import './main.scss';

const InfoMainHome = {
    altPadrao: 'THL - Soluções em tecnologia',
}
const MainHome = () => {

    return (
        <section>
            <StaticImage className="main-image-bg" src="../../images/bgHome.webp" alt={`${InfoMainHome.altPadrao}`} />
        </section>
    )
}

export default MainHome;
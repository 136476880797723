import * as React from "react"

// Rotas
import Cabecalho from "../Components/Header/Header";
import ContentMain from "../Components/Main/ConteudoHome";
import MainHome from "../Components/Main/MainHome";
import SEO from "../Components/seo";
import CarouselH from "../Components/Carousel/CarouselHome";
import Responsabilidades from "../Components/CompRespon/Competencias";
import Cases from "../Components/Cases/CasesDeSucesso";
import Fornecedores from "../Components/Fornecedores/Fornecedor";
import Manutencao from "../Components/Manutencao/locacao";
import ContatoForm from "../Components/Formulario/FormContato";
import PaginaBlog from "../Components/Blog/Posts";
import NewsLaterEmail from "../Components/newslatter/Newslater";
import Footer from "../Components/Footer/Rodape";
import DireitosReservados from "../Components/Footer/copyright";

const InfoHome = {
  titulo: 'THL - Soluções em tecnologia', 
}

const IndexPage = () => {
  return (
    <>
      <SEO title={`${InfoHome.titulo}`}/>
      <Cabecalho/>
      <MainHome/>   
      <ContentMain/>
      <CarouselH/>
      <Responsabilidades/>
      <Cases/>
      <Fornecedores/>
      <Manutencao/>
      <ContatoForm/>
      <PaginaBlog/>
      <NewsLaterEmail/>
      <Footer/>
      <DireitosReservados/>
    </>
  )
}

export default IndexPage;

import React from 'react';
import './formulario.scss';

const ContatoForm = () => {

    const ContentForm = {
        titulo: 'Como podemos te ajudar?',
        describe: 'Estamos aqui para te ajudar! Para esclarecer dúvidas ou solicitar orçamento, preencha o formulário.',
    }
    return (
        <section className='formulario-wrapper'>
            <div className='formulario-info-wrapper'>
                <div>
                    <h3>{`${ContentForm.titulo}`}</h3>
                    <p>{`${ContentForm.describe}`}</p>
                </div>
            </div>
            <div className='formulario-form-wrapper'>
                <div className="formulario">
                    <div className='formulario-escopo-do-form'>
                        <div className="alinhamento-form-contato">
                            <form method="POST" action="https://formsubmit.co/mkt@digitalid.com.br">
                                <input type="text" name="Nome" className="inputs" placeholder="Nome" required/>
                                <input type="email" name="Email" className="inputs" placeholder="Email" required/>
                                <textarea type="text" name="Mensagem" className="msg-form" placeholder="Mensagem" style={{ resize:"none"}} maxLength="80" required/>
                                <input type="hidden" name="_captcha" value="false"/>
                                <input type="hidden" name="_next" value="https://digitalid.com.br/"></input>
                                <button className="link-btn-contato" type="submit">Enviar</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContatoForm;
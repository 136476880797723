import React from "react";
import './main.scss';


const InfoMainHome = {
    titulo: 'Soluções em tecnologia',
    describe: 'Garantindo soluções com qualidade tecnológica de última ponta, segurança e um ótimo custo benefício.',
    altPadrao: 'THL - Soluções em tecnologia',
    botao: 'Conheça mais',
}
const ContentMain = () => {

    return (
        <section className="main-conteudo-home">
            <div className='main-info-wrapper'>
                <h1>{`${InfoMainHome.titulo}`}</h1>
                <p>{`${InfoMainHome.describe}`}</p>
                <a href="/" alt={`${InfoMainHome.altPadrao}`}>
                    {`${InfoMainHome.botao}`}
                </a>
            </div>
        </section>
    )
}

export default ContentMain;
import React from 'react';
import { FaPhone, FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { StaticImage } from 'gatsby-plugin-image'
import './header.scss';

const infoContatoHeader = {
    telefone: '(41) 3286-6090',
    email: 'comercial@thlsolucoes.com.br',
    altPadrao: 'THL - Soluções em tecnologia',
}

const Cabecalho = () => {

    return (
        <header className='header-wrapper'>
            <div className='header-barra-de-contato'>
                <div className='header-contato-wrap'>
                    <a href="/" target="blank" alt={`${infoContatoHeader.altPadrao}`}>
                        <span><FaPhone/></span> {`${infoContatoHeader.telefone}`}
                    </a>
                    <a href="/" target="blank" alt={`${infoContatoHeader.altPadrao}`}>
                        {`${infoContatoHeader.email}`}
                    </a>
                </div>
                <div className='header-redes-sociais-wrap'>
                    <a href="/" target="blank" alt={`${infoContatoHeader.altPadrao}`}>
                        <span><FaFacebook/></span>
                    </a>
                    <a href="/" target="blank" alt={`${infoContatoHeader.altPadrao}`}>
                        <span><FaInstagram/></span>
                    </a>
                    <a href="/" target="blank" alt={`${infoContatoHeader.altPadrao}`}>
                        <span><FaLinkedin/></span>
                    </a>
                </div>    
            </div>
            <main className='header-wrap-main'>
                <div className='header-logo-wrap'>
                    <a href="/">
                        <StaticImage src='../../images/logo.webp' loading='eager' placeholder='blurred' alt={`${infoContatoHeader.altPadrao}`}/>
                    </a>
                </div>
                <div className='header-logo-wrap'>
                    <ul className='header-list-menu'>
                        <li><a href="/" alt={`${infoContatoHeader.altPadrao}`}>
                            Empresa
                        </a></li>
                        <li><a href="/" alt={`${infoContatoHeader.altPadrao}`}>
                            Soluções
                        </a></li><li><a href="/" alt={`${infoContatoHeader.altPadrao}`}>
                            Fornecedores
                        </a></li><li><a href="/" alt={`${infoContatoHeader.altPadrao}`}>
                            Clientes
                        </a></li><li><a href="/" alt={`${infoContatoHeader.altPadrao}`}>
                            Blog
                        </a></li><li><a href="/" alt={`${infoContatoHeader.altPadrao}`}>
                            Contato
                        </a></li>
                    </ul>
                </div>
                <div className='header-logo-wrap'>
                    <div className='header-btn-wrap'>
                        <a href="/" target="blank" alt={`${infoContatoHeader.altPadrao}`}>
                            Fale com um especialista
                        </a>
                    </div>
                </div>
            </main>           
        </header>
    )
}

export default Cabecalho;
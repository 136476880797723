import React from 'react';
import './footer.scss';

const DireitosReservados = () => {

    const InfoCopy = {
        describe: 'THL Soluções em Tecnologia | Todos os direitos reservados | Política de Privacidade',
        author: 'Created by @DigitalID',
    }
    return (
        <div className='copy-wrapper'>
            <p>{`${InfoCopy.describe}`}</p>
            <p>{`${InfoCopy.author}`}</p>
        </div>
    )
}

export default DireitosReservados;
import React from "react";
import { StaticImage } from 'gatsby-plugin-image';
import './manutencao.scss';

const Manutencao = () => {

    const InfoLocation = {
        titulo: 'Locação e Manutenção',
        describe: 'Atualmente, virou uma necessidade para as instituições possuir certas máquinas, câmeras, headsets, etc. E ter alguém que cuide da sua locação e manutenção pode ser um alívio.',
    } 
    return (
        <section className="locacao-wrap">
            <div className="locacao-info-wrap">
                <h3>{`${InfoLocation.titulo}`}</h3>
                <p>{`${InfoLocation.describe}`}</p>
            </div>
            <div className="locacao-image-wrapper">
                <div className="locacao-imagem-escopo">
                    <StaticImage className="locacao-image" src="../../images/THL_Solucoes.png" placeholder="blurred" loading="lazy" alt="THL - Locação e manutenção"/>
                </div>
            </div>
        </section>
    )
}

export default Manutencao;
import React from 'react';
import { BsFillArrowRightSquareFill } from 'react-icons/bs';
import './newslatter.scss';

const NewsLaterEmail = () => {

    const infonNews = {
        titulo: 'Inscreva-se para receber mais conteúdos',
        describe: 'Forneça seu e-mail e entre em contato conosco para mais informações.',
    }
    return (
        <>
            <section className='news-wrapper'>
                <div className='news-text-wrapper'>
                    <div className='news-text-align'>
                        <h3>{`${infonNews.titulo}`}</h3>
                        <p>{`${infonNews.describe}`}</p>
                    </div>
                    <form className='news-input-wrapper' action="POST">
                        <input type="email" placeholder='Seu e-mail' required/>
                        <button className='news-icon-arrow-right'>
                            <BsFillArrowRightSquareFill/>
                        </button>
                    </form>
                </div>
            </section>
        </>
    )
}

export default NewsLaterEmail;
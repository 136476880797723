import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import './blog.scss';
import { CardsBlog } from './CardsPost';

const PaginaBlog = () => {
    
    const InfoBlog = {
        titulo: 'Nosso Blog',
        tituloPost01: 'Triagem por temperatura',
        tituloPost02: 'Funcionalidades do sistema LPR',
        tituloPost03: 'Soluções para vídeo chamada',
        describePost01: 'Nós, da THL, também oferecemos soluções para triagem por temperatura, você sabia? Sendo essencial para muitos ...',
        describePost02: 'O sistema LPR oferece possibilidades ilimitadas de aplicações, gerenciamento usuários, áreas de estacionamentos, frotas...',
        describePost03: 'Conhece nossas soluções para vídeo conferência? Pelo período em que estamos passando, muitas instituições adotaram 100% ...',
        DataPost: '09 Jun 2021',
        rotaPost01: '/',
    }

    return (
        <main className='blog-wrapper-content'>
            <h2>{`${InfoBlog.titulo}`}</h2>
            <div className='blog-posts-wrapper'>
                <div className='blog-post-card-wrapper'>
                    <StaticImage src="../../images/THLimage.png" placeholder="blurred" loading="eager" alt="Soluções THL - Tecnologia"/>
                    <CardsBlog titulo={`${InfoBlog.tituloPost01}`} descricao={`${InfoBlog.describePost01}`} data={`${InfoBlog.DataPost}`}
                    Rota={`${InfoBlog.rotaPost01}`}/>
                </div>
                <div className='blog-post-card-wrapper'>
                    <StaticImage src="../../images/funcionalidades.png" placeholder="blurred" loading="eager" alt="Soluções THL - Tecnologia"/>
                    <CardsBlog titulo={`${InfoBlog.tituloPost02}`} descricao={`${InfoBlog.describePost02}`} data={`${InfoBlog.DataPost}`}
                    Rota={`${InfoBlog.rotaPost01}`}/>
                </div>
                <div className='blog-post-card-wrapper'>
                    <StaticImage src="../../images/solucoes.png" placeholder="blurred" loading="eager" alt="Soluções THL - Tecnologia"/>
                    <CardsBlog titulo={`${InfoBlog.tituloPost03}`} descricao={`${InfoBlog.describePost03}`} data={`${InfoBlog.DataPost}`}
                    Rota={`${InfoBlog.rotaPost01}`}/>
                </div>
            </div>
            <div className='blog-post-botao-principal'>
                <a href="/" target="blank" alt="THL - ver mais posts">
                    veja mais
                </a>
            </div>
        </main>
    )
}

export default PaginaBlog;
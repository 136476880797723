import React from "react";
import { FaFacebook, FaInstagram, FaPhone } from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';
import { StaticImage } from 'gatsby-plugin-image';
import './footer.scss';

const Footer = () => {
    
    const InfoFooter = {
        describe: 'Garantimos soluções com qualidade tecnológica de última ponta, segurança e um ótimo custo benefício.',
    }
    return (
        <footer>
            <div className="footer-card">
                <div className="footer-logo-wrapper">
                    <StaticImage src="../../images/logo.png" placeholder="blurred" loading="eager"/>
                </div>
                <p>
                    {`${InfoFooter.describe}`}
                </p>
                <div className="footer-redes-sociais-wrapper">
                    <ul>
                        <li><FaFacebook/></li>
                        <li><FaInstagram/></li>
                        <li><HiOutlineMail/></li>
                        <li><FaPhone/></li>
                    </ul>
                </div>
            </div>
            <div className="footer-card-center">
                <div className="footer-redirecionamento">
                    <a className="footer-titulo-page" href="/" target="blank" alt="THL - Soluções para tecnologia">
                        Empresa
                    </a>
                    <ul className="footer-empresa-pages-wrapper">
                        <li>Seleções</li>
                        <li>Fornecedores</li>
                        <li>Clientes</li>
                        <li>Blog</li>
                    </ul>
                </div>
            </div>
            <div className="footer-card">
                <a className="footer-titulo-page" href="/" target="blank" alt="THL - Soluções para tecnologia">
                    Entre em contato
                </a>
                <div className="espacamento-footer"></div>
                <ul className="footer-contato-pages-wrapper">
                    
                    <li>Horário de Funcionamento</li>
                    <p>Segunda a Sexta-feira das 8h às 18h</p>
                    
                    <li>E-mail</li>
                    <p>comercial@thlsolucoes.com.br</p>
                    
                    <li>Telefone</li>
                    <p>(41) 3286-6090</p>
                </ul>
            </div>
        </footer>
    )
}

export default Footer;
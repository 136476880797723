import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { StaticImage } from "gatsby-plugin-image";

import "swiper/css";
import "swiper/css/pagination";
import './CarouselH.scss';
// import required modules

export default function CarouselH () {

    const InfoCarroseo = {
        titulo: 'Nossas Soluções',
    }
  return (
    <section className="wrapper-carrousel">
        <div className="carrouseo-wrapper-describe">
            <h2>{`${InfoCarroseo.titulo}`}</h2>
        </div>
      <Swiper
        slidesPerView={3}
        spaceBetween={30} 
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
            <div className="carousel-card-texto-imagem">
                <StaticImage className='carousel-caixa-image'  src="../../images/MonitoramentoIP.png" loading='eager' placeholder='blurred'/> 
                <p>Monitoramento IP</p>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className="carousel-card-texto-imagem">
                <StaticImage className='carousel-caixa-image'  src="../../images/TelefoniaEmNuvem.png" loading='eager' placeholder='blurred'/> 
                <p>Telefonia em Nuvem</p>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className="carousel-card-texto-imagem">
                <StaticImage className='carousel-caixa-image'  src="../../images/HomeOffice.png" loading='eager' placeholder='blurred'/> 
                <p>Home Office</p>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className="carousel-card-texto-imagem">
                <StaticImage className='carousel-caixa-image'  src="../../images/VídeoColaboracao.png" loading='eager' placeholder='blurred'/> 
                <p>Vídeo Colaboração</p>
            </div>
        </SwiperSlide>
      </Swiper>
    </section>
  );
}

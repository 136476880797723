import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { StaticImage } from "gatsby-plugin-image";
import './fornecedor.scss';

const Fornecedores = () => {
    
    const infoFornecedor = {
        tituto: 'Nossos Fornecedores',
    }
    return (
        <section className='forn-wrapper'>
            <div className='forn-info-wrap'>
                <h2>{`${infoFornecedor.tituto}`}</h2>
            </div>
            <Swiper
                slidesPerView={3}
                spaceBetween={30} 
                pagination={{
                clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <div className="carousel-card-texto-imagem">
                        <StaticImage className='carousel-caixa-image'  src="../../images/Marca.png" loading='eager' placeholder='blurred'/> 
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="carousel-card-texto-imagem">
                        <StaticImage className='carousel-caixa-image'  src="../../images/Marca.png" loading='eager' placeholder='blurred'/> 
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="carousel-card-texto-imagem">
                        <StaticImage className='carousel-caixa-image'  src="../../images/Marca.png" loading='eager' placeholder='blurred'/> 
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="carousel-card-texto-imagem">
                        <StaticImage className='carousel-caixa-image'  src="../../images/Marca.png" loading='eager' placeholder='blurred'/> 
                    </div>
                </SwiperSlide>
            </Swiper>
        </section>
    )
}

export default Fornecedores;
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import './responsabilidades.scss';

const Responsabilidades = () => {

    const InfoResp = {
        titulo: 'Competência e Responsabilidade',
        subtitulo: 'Por que escolher a',
        describe: 'Sendo uma empresa planejada para as necessidades do seu negócio, a THL conta com uma competente e focada equipe para além de promover o serviço, também oferecer qualidade e responsabilidade na entrega do mesmo.',
    }

    return (
        <main className="resp-wrapper">
            <StaticImage className="resp-banner" src="../../images/THlBanner.png" />
            <div className="resp-text-wrap">
                <h4>{`${InfoResp.subtitulo}`} <strong>THL</strong> ?</h4>
                <h2>{`${InfoResp.titulo}`}</h2>
                <p>{`${InfoResp.describe}`}</p>
                <span><i></i></span>
            </div>
        </main>
    )
}

export default Responsabilidades;